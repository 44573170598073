var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "dropdown-container",
    },
    [
      _c(
        "a",
        {
          staticClass: "icon-dropdown-trigger",
          attrs: { disabled: _vm.disabled },
          on: {
            click: function ($event) {
              _vm.open = !_vm.open
            },
          },
        },
        [
          _c("i", {
            class: ["fas", _vm.iconClass],
            staticStyle: { "font-size": "1.25rem!important" },
          }),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.open,
              expression: "open",
            },
          ],
          staticClass: "icon-dropdown-container",
        },
        _vm._l(_vm.options, function (option) {
          return _c(
            "div",
            {
              key: option.name,
              staticStyle: { width: "100%", "z-index": "9999" },
            },
            [
              _c(
                "button",
                {
                  staticClass: "button icon-dropdown-item",
                  style: option.style,
                  attrs: { disabled: option.disabled },
                  on: {
                    click: function () {
                      option.action()
                      _vm.open = false
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.name) + " ")]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }