var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "is-fullwidth" }, [
    _c("div", [
      _vm.sortedRecords.length > 0 && (!_vm.isLoading || _vm.noResults)
        ? _c("div", [
            _c(
              "table",
              {
                staticClass: "table is-striped is-fullwidth",
                staticStyle: {
                  "max-height": "calc(100% - 70px)",
                  "overflow-y": "auto",
                  margin: "0",
                },
              },
              [
                _vm._m(0),
                _c(
                  "tbody",
                  _vm._l(_vm.sortedRecords, function (record) {
                    return _c(
                      "tr",
                      {
                        key: record.runId,
                        staticStyle: {
                          "max-height": "35px!important",
                          height: "35px!important",
                          overflow: "hidden!important",
                        },
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass: "is-monospaced",
                            staticStyle: {
                              "max-height": "35px!important",
                              height: "35px!important",
                              overflow: "hidden!important",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.getImportType(record.name)) + " "
                            ),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "is-monospaced name-container",
                            staticStyle: {
                              "max-height": "35px!important",
                              height: "35px!important",
                              overflow: "hidden!important",
                            },
                          },
                          [
                            _c("div", { staticClass: "name" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.top-center",
                                      value: {
                                        content: _vm.getFileName(
                                          record.original_path
                                        ),
                                      },
                                      expression:
                                        "{\n                    content: getFileName(record.original_path)\n                  }",
                                      modifiers: { "top-center": true },
                                    },
                                  ],
                                  staticClass: "tooltip-hover",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getFileName(record.original_path)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _c(
                          "td",
                          {
                            staticClass: "is-monospaced",
                            staticStyle: {
                              "max-height": "35px!important",
                              height: "35px!important",
                              overflow: "hidden!important",
                            },
                          },
                          [
                            record.hadError
                              ? _c(
                                  "v-popover",
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(record.status) +
                                        " " +
                                        _vm._s(
                                          record.hadError ? "(with error)" : ""
                                        ) +
                                        " "
                                    ),
                                    _c(
                                      "template",
                                      {
                                        attrs: { id: "popover" },
                                        slot: "popover",
                                      },
                                      [_vm._v(" " + _vm._s(record.error) + " ")]
                                    ),
                                  ],
                                  2
                                )
                              : _c(
                                  "span",
                                  {
                                    class: {
                                      "has-text-danger":
                                        record.status === "Failed",
                                      "text-warning":
                                        record.status === "Running Load",
                                      "text-grey":
                                        record.status === "Starting Load",
                                      "text-info":
                                        record.status === "Load Complete" ||
                                        record.status === "File Not Found",
                                      "semi-bold":
                                        record.status === "Running Load",
                                      "font-italic":
                                        record.status === "Running Load",
                                      magnify: record.status === "Running Load",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        record.status === "File Not Found"
                                          ? "Load Complete"
                                          : record.status
                                      )
                                    ),
                                  ]
                                ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          {
                            staticStyle: {
                              "max-height": "35px!important",
                              height: "35px!important",
                              overflow: "hidden!important",
                            },
                          },
                          [
                            _c("span", { staticClass: "is-monospaced" }, [
                              _vm._v(_vm._s(_vm.moment(record.created_at))),
                            ]),
                            _vm.getRunDiff(record.created_at) > 30 &&
                            record.status !== "Load Complete" &&
                            record.status !== "Pending Transform"
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticStyle: { "margin-left": "5px" },
                                      attrs: {
                                        href: "javascript:void()",
                                        alt: "Try to unjam the process",
                                      },
                                      on: { click: _vm.unjamProcess },
                                    },
                                    [
                                      !_vm.isUnjamming
                                        ? _c("i", {
                                            class: ["fas fa-redo"],
                                            attrs: { alt: "refresh-icon" },
                                          })
                                        : _c("i", {
                                            staticClass:
                                              "fas fa-spinner fa-spin fa-pulse",
                                          }),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "paginator",
                staticStyle: {
                  position: "sticky",
                  bottom: "0",
                  "background-color": "white",
                  "border-top": "1px solid #ededed",
                },
              },
              [
                _c("div", { staticClass: "paginator__left is-monospaced" }, [
                  _c("span", [
                    _c("b", [
                      _vm._v(_vm._s(_vm.commaNumberDisplay(_vm.total))),
                    ]),
                    _vm._v(" results ("),
                    _c("b", [
                      _vm._v(_vm._s(_vm.commaNumberDisplay(_vm.results))),
                    ]),
                    _vm._v(" per page)"),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "paginator-middle" },
                  [
                    _c(
                      "button",
                      {
                        attrs: { disabled: _vm.page === 0 },
                        on: {
                          click: function ($event) {
                            _vm.page = 0
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 512 512",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192zm384-192l-192 192c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L301.3 256 470.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        attrs: { disabled: _vm.page === 0 },
                        on: {
                          click: function ($event) {
                            _vm.page = _vm.page - 1
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-chevron-left" })]
                    ),
                    _vm._l(_vm.paginationPages, function (_page) {
                      return _c(
                        "button",
                        {
                          key: _page,
                          class: {
                            "is-active": _page === _vm.page,
                          },
                          on: {
                            click: function ($event) {
                              _vm.page = _page
                            },
                          },
                        },
                        [_c("a", [_vm._v(" " + _vm._s(_page + 1) + " ")])]
                      )
                    }),
                    _c(
                      "button",
                      {
                        attrs: {
                          disabled:
                            _vm.page === Math.ceil(_vm.total / _vm.results) - 1,
                        },
                        on: {
                          click: function ($event) {
                            _vm.page = _vm.page + 1
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-chevron-right" })]
                    ),
                    _c(
                      "button",
                      {
                        attrs: {
                          disabled:
                            _vm.page === Math.ceil(_vm.total / _vm.results) - 1,
                        },
                        on: {
                          click: function ($event) {
                            _vm.page = Math.ceil(_vm.total / _vm.results) - 1
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 512 512",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-192-192c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 233.4 425.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l192-192zm-384 192l192-192c12.5-12.5 12.5-32.8 0-45.3l-192-192c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 41.4 425.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  2
                ),
                _c("div", { staticClass: "paginator__right is-monospaced" }, [
                  _c("div", [
                    _c("span", [
                      _c("b", [_vm._v(" " + _vm._s(_vm.page + 1) + " ")]),
                    ]),
                    _c("span", [_vm._v("of ")]),
                    _c("span", [
                      _c("b", [
                        _vm._v(_vm._s(Math.ceil(_vm.total / _vm.results))),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm.jobRecords.length == 0 && (!_vm.isLoading || _vm.noResults)
        ? _c("div", { staticClass: "not-found" }, [
            _c(
              "div",
              { staticClass: "has-text-centered", attrs: { colspan: "4" } },
              [_vm._v("No records founds")]
            ),
          ])
        : _c("div", [_c("LoadingVue")], 1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "100px" } }, [_vm._v("Job")]),
        _c(
          "th",
          { staticClass: "ellipsis", staticStyle: { "max-width": "" } },
          [_vm._v(" Name ")]
        ),
        _c("th", { staticStyle: { width: "200px" } }, [_vm._v("Status")]),
        _c("th", { staticStyle: { width: "250px" } }, [_vm._v("Start Time")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }