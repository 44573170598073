var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading-wrapper" }, [
    _c(
      "span",
      { staticClass: "icon is-large" },
      [
        _c("spin-loader"),
        _c(
          "span",
          {
            class: ["is-size-4", "has-text-" + _vm.color],
            staticStyle: { "padding-left": "20px" },
          },
          [_vm._v("Loading...")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }