var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageName))]
          ),
        ]
      ),
      _vm.$hasPermissions(_vm.clientSession, ["ASSET_REPORT"], 1)
        ? _c(
            "div",
            {
              staticStyle: {
                "max-width": "95%",
                margin: "auto",
                overflow: "hidden",
                padding: "1rem",
              },
              attrs: { id: "body-content-area" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "jobs-container",
                  staticStyle: { display: "flex", "flex-direction": "column" },
                },
                [
                  _c("div", { staticClass: "jobs-container-tabs" }, [
                    _c(
                      "div",
                      {
                        staticClass: "jobs-container-tabs--tab",
                        class: { "is-active": _vm.reportType === "jobs" },
                        on: {
                          click: function ($event) {
                            _vm.reportType = "jobs"
                          },
                        },
                      },
                      [_c("span", [_vm._v("Jobs")])]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "jobs-container-tabs--tab",
                        class: { "is-active": _vm.reportType === "Salesforce" },
                        on: {
                          click: function ($event) {
                            _vm.reportType = "Salesforce"
                          },
                        },
                      },
                      [_c("span", [_vm._v("Salesforce")])]
                    ),
                    _c(
                      "div",
                      { staticClass: "jobs-container-tabs--tools" },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void()" },
                            on: { click: _vm.doRefresh },
                          },
                          [_c("i", { staticClass: "fas fa-sync-alt" })]
                        ),
                        _vm.reportType === "Salesforce" &&
                        _vm.$hasPermissions(_vm.clientSession, [
                          "ASSET_REPORT",
                          2,
                        ])
                          ? _c("IconDropdown", {
                              attrs: {
                                disabled: _vm.disableNewJobs,
                                "icon-class": "fa-plus",
                                options: _vm.importOptions,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "jobs-container--content" },
                    [
                      _vm.reportType === "jobs"
                        ? _c(
                            "div",
                            { staticClass: "jobs-container-content-search" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.search,
                                    expression: "search",
                                  },
                                ],
                                staticClass: "input",
                                staticStyle: {
                                  "max-width": "calc(100% - 210px !important)",
                                },
                                attrs: { type: "text", placeholder: "Search" },
                                domProps: { value: _vm.search },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.performSearch()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.search = $event.target.value
                                  },
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "control" },
                                [
                                  _c("Select", {
                                    staticStyle: {
                                      width: "200px",
                                      "margin-left": "10px",
                                      "z-index": "1000",
                                    },
                                    attrs: {
                                      buttonText: _vm.selectedFilter,
                                      options: _vm.compFilterOptions,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-info",
                                  staticStyle: {
                                    width: "200px",
                                    "margin-left": "10px",
                                    display: "flex",
                                    "align-items": "center",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.performSearch()
                                    },
                                  },
                                },
                                [
                                  _vm._m(0),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        width: "calc(100% - 25px)",
                                      },
                                    },
                                    [_vm._v(" Search ")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm.reportType === "Salesforce"
                        ? _c("SalesForceRuns", {
                            ref: "salesForceRuns",
                            attrs: {
                              search: _vm.searchVal,
                              refresh: _vm.refresh,
                            },
                            on: {
                              hasRunningJob: function (value) {
                                return (_vm.disableNewJobs = value)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.reportType === "jobs"
                        ? _c("JobsRuns", {
                            staticClass: "jobs-container-content-body",
                            attrs: {
                              search: _vm.searchVal,
                              refresh: _vm.refresh,
                              filterVal: _vm.filterVal,
                            },
                            on: {
                              noRecordsFound: function ($event) {
                                return (_vm.jobsHaveNoRecords = $event)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _c("div", [_vm._m(1)]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticStyle: { width: "25px" } }, [
      _c("i", { staticClass: "fa fa-search" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "has-text-centered" }, [
      _c("h1", { staticClass: "is-size-6" }, [
        _vm._v("Please choose an option from the sidebar menu"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }